import { Modal, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import Swal from "sweetalert2";
import React, { useState } from "react";
import withReactContent from "sweetalert2-react-content";

const LoginModal = ({ show, handleClose, setLoggedIn }) => {
  const [showLoginModal, setShowLoginModal] = useState(true);
  const [showRegisterModal, setShowRegisterModal] = useState(false);

  const schema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
  });
  const schema2 = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
    nombre: yup.string().required(),
    apellido: yup.string().required(),
    telefono: yup.string().required(),
    empresa: yup.string(),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(showRegisterModal ? schema2 : schema) });

  const onSubmit = (data) => {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
      title: <p>Cargando...</p>,
      backdrop: false,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    }).then(() => { });
    console.log(data);

    let url;
    if (showRegisterModal) {
      url = `https://dtainternacional.com/apidta/registrar.php`;
    } else {
      url = `https://dtainternacional.com/apidta/login.php`;
    }

    axios
      .post(url, data, {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        MySwal.close();
        console.log(response.data);
        if (response.data.status === "ERROR") {
          MySwal.fire({
            icon: "warning",
            title: "Advertencia",
            text: response.data.mensaje,
          });
        } else {
          if (showRegisterModal) {
            MySwal.fire({
              icon: "success",
              title: "Usuario registrado correctamente",
              backdrop: false,
              allowOutsideClick: false,
            }).then(() => {
              reset();
              closeRegisterModal();
            });
          } else {
            reset();
            localStorage.setItem(
              "LoginSession",
              JSON.stringify(response.data[0])
            );
            localStorage.setItem("isLoggedIn", "true");
            setLoggedIn(true);

            const items = JSON.parse(localStorage.getItem("LoginSession"));
            if (items) {
              console.log(items);
            }
            handleClose();
          }
        }
      })
      .catch((error) => {
        MySwal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.mensaje,
        });
      });
  };

  const openRegisterModal = () => {
    reset();
    setShowLoginModal(false);
    setShowRegisterModal(true);
  };

  const closeRegisterModal = () => {
    setShowLoginModal(true);
    setShowRegisterModal(false);
    reset();
  };

  const registerMod = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Header closeButton>
        <Modal.Title>Registrarse</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row g-3">
          <div className="col-12">
            <div className="form-floating">
              <input
                type="text"
                className={
                  errors.nombre ? "form-control border-danger" : "form-control "
                }
                id="empresa"
                name="empresa"
                placeholder="Nombre de la empresa"
                {...register("empresa")}
              />
              <label htmlFor="empresa">Nombre de la empresa</label>
            </div>
          </div>
          <div className="col-12">
            <div className="form-floating">
              <input
                type="text"
                className={
                  errors.nombre ? "form-control border-danger" : "form-control "
                }
                id="nombre"
                name="nombre"
                placeholder="nombre"
                {...register("nombre")}
              />
              <label htmlFor="nombre">Nombre</label>
            </div>
          </div>
          <div className="col-12">
            <div className="form-floating">
              <input
                type="text"
                className={
                  errors.apellido
                    ? "form-control border-danger"
                    : "form-control "
                }
                id="apellido"
                name="apellido"
                placeholder="apellido"
                {...register("apellido")}
              />
              <label htmlFor="apellido">Apellido</label>
            </div>
          </div>
          <div className="col-12">
            <div className="form-floating">
              <input
                type="text"
                className={
                  errors.telefono
                    ? "form-control border-danger"
                    : "form-control "
                }
                id="telefono"
                name="telefono"
                placeholder="telefono"
                {...register("telefono")}
              />
              <label htmlFor="telefono">Tel&eacute;fono</label>
            </div>
          </div>

          <div className="col-12">
            <div className="form-floating">
              <input
                type="email"
                className={
                  errors.email ? "form-control border-danger" : "form-control "
                }
                id="email"
                name="email"
                placeholder="Correo"
                {...register("email")}
              />
              <label htmlFor="email">Correo</label>
            </div>
          </div>
          <div className="col-12">
            <div className="form-floating">
              <input
                type="password"
                className={
                  errors.password
                    ? "form-control border-danger"
                    : "form-control "
                }
                id="password"
                name="password"
                placeholder="Contrase&ntilde;a"
                {...register("password")}
              />
              <label htmlFor="password">Contrase&ntilde;a</label>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <small
          className="text-decoration-underline"
          onClick={closeRegisterModal}
          style={{ cursor: "pointer" }}
        >
          Ya est&aacute; Registrado?
        </small>
        <Button variant="primary" type="submit">
          Registrarse
        </Button>
      </Modal.Footer>
    </form>
  );

  const loginMod = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Header closeButton>
        <Modal.Title>Iniciar Sesi&oacute;n</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row g-3">
          <div className="col-12">
            <div className="form-floating">
              <input
                type="email"
                className={
                  errors.email ? "form-control border-danger" : "form-control "
                }
                id="email"
                name="email"
                placeholder="Correo"
                {...register("email")}
              />
              <label htmlFor="email">Correo</label>
            </div>
          </div>
          <div className="col-12">
            <div className="form-floating">
              <input
                type="password"
                className={
                  errors.password
                    ? "form-control border-danger"
                    : "form-control "
                }
                id="password"
                name="password"
                placeholder="Contrase&ntilde;a"
                {...register("password")}
              />
              <label htmlFor="password">Contrase&ntilde;a</label>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <small
          className="text-decoration-underline"
          style={{ cursor: "pointer" }}
          onClick={openRegisterModal}
        >
          No tiene cuenta?
        </small>
        <Button variant="primary" type="submit">
          Iniciar sesi&oacute;n
        </Button>
      </Modal.Footer>
    </form>
  );

  let modal;
  if (showLoginModal) {
    modal = loginMod;
  } else {
    modal = registerMod;
  }
  return (
    <Modal show={show} onHide={handleClose}>
      {modal}
    </Modal>
  );
};

export default LoginModal;
