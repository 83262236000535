import { Component } from "react";

import DataTable from "react-data-table-component";

const customStyles = {
  table: {
    style: {
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "gray",
    },
  },
  rows: {
    style: {
      fontSize: "16px",
    },
  },
  head: {
    style: {
      fontSize: "20px",
      fontWeight: "bold",
    },
  },
};

const columns = [
  {
    name: "Title",
    selector: (row) => row.title,
  },
  {
    name: "Year",
    selector: (row) => row.year,
  },
];

const data = [
  {
    id: 1,
    title: "Beetlejuice",
    year: "1988",
  },
  {
    id: 2,
    title: "Ghostbusters",
    year: "1984",
  },
  {
    id: 2,
    title: "Ghostbusters",
    year: "1984",
  },
  {
    id: 2,
    title: "Ghostbusters",
    year: "1984",
  },
  {
    id: 2,
    title: "Ghostbusters",
    year: "1984",
  },
];

class NewsletterPanel extends Component {
  render() {
    return (
      <>
        <div className="container">
          <DataTable
            title="Usuarios Registrados"
            columns={columns}
            data={data}
            selectableRows
            direction="auto"
            fixedHeaderScrollHeight="300px"
            pagination
            responsive
            subHeaderAlign="right"
            subHeaderWrap
            customStyles={customStyles}
            striped
            fixedHeader
            highlightOnHover
          />
        </div>
      </>
    );
  }
}

export default NewsletterPanel;
