import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import LoginModal from "./LoginModal";

export default function Header() {
  const [isSticky, setIsSticky] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const loggedInStatus = localStorage.getItem("isLoggedIn");
    if (loggedInStatus === "true") {
      setLoggedIn(true);

      const user = JSON.parse(localStorage.getItem("LoginSession"));
      setUserInfo(user);
    }
    console.log(loggedInStatus);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("LoginSession");
    setLoggedIn(false);
    setUserInfo(null);
  };
  const DropdownItem = (props) => {
    const imageurl = `/img/img2/${props.name}.jpg`;
    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col">
              <Link to="/lubriguard" className="dropdown-item">
                <img alt="logo" src={imageurl} height="220px" />
              </Link>
            </div>
            <small className="text-center">Lubriguard</small>
            <div className="col">
              <Link to="/lubriguard" className="dropdown-item">
                <img alt="logo" src={imageurl} height="220px" />
              </Link>
            </div>
            <small className="text-center">Lubriguard</small>
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 90) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  return (
    <>
      <header>
        {/* Topbar Start */}
        <div className="container-fluid bg-light d-none d-lg-block pt-2 pb-4">
          <div className="row align-items-center top-bar">
            <div className="col-lg-3 col-md-12">
              <a href="/" className="navbar-brand ms-4 ">
                <img
                  alt="logo"
                  src="/img/logodtacrop.png"
                  className="img-fluid"
                  width="70px"
                />
              </a>
            </div>
            <div
              className="col-lg-9 col-md-12 text-end"
              style={{ fontSize: 1.1 + "rem" }}
            >
              <div className="h-100 d-inline-flex align-items-center me-4">
                <i className="fa fa-map-marker-alt text-primary me-2" />
                <p className="m-0">
                  Calle Seibo No. 5 casi Esq. San Martin, Villa Juana, Santo
                  Domingo.
                </p>
              </div>
              <div className="h-100 d-inline-flex align-items-center">
                <a
                  className="btn btn-sm-square bg-white text-primary me-1"
                  href="/"
                >
                  <i className="fab fa-facebook-f" />
                </a>
                <a
                  className="btn btn-sm-square bg-white text-primary me-0"
                  href="/"
                >
                  <i className="fab fa-instagram" />
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* Topbar End */}
        {/* Navbar Start */}
        <div
          className={`container-fluid bg-light nav-bar ${isSticky ? "sticky-top shadow" : ""
            }`}
        >
          <nav className="navbar navbar-expand-lg navbar-light bg-white mx-2 p-2 py-lg-0 px-lg-4">
            <a
              href="/"
              className="navbar-brand d-flex align-items-center m-0 p-0 d-lg-none"
            >
              <img
                src="/img/logodtacrop.png"
                className="img-fluid"
                width="60px"
                alt="logo"
              />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
            >
              <span className="fa fa-bars" />
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <div className="navbar-nav me-auto">

                <Link className="nav-item nav-link nav-texto" to="/">
                  Inicio
                </Link>
                <a href="/" className="nav-item nav-link nav-texto">
                  Nosotros
                </a>
                <div className=" nav-item dropdown">
                  <a
                    href="/"
                    className="nav-link dropdown-toggle nav-texto"
                    data-bs-toggle="dropdown"
                  >
                    Productos
                  </a>
                  <div className="prodnav dropdown-menu fade-up m-0">
                    <div className="dropdown-item dropend">
                      <Link className="dropdown-toggle" to="/lubricantes">
                        Lubricantes
                      </Link>
                      <div
                        className="dropdown-menu show"
                        data-bs-popper="none"
                        style={{ display: "flex" }}
                      >
                        <DropdownItem name="Lubriguard" />
                        <DropdownItem name="Lubriguard" />
                        <DropdownItem name="Lubriguard" />
                      </div>
                    </div>

                    <Link className="dropdown-item" to="/baterias">
                      Bater&iacute;as
                    </Link>
                    <Link className="dropdown-item" to="/transmisiones">
                      Transmisiones
                    </Link>

                    <Link className="dropdown-item" to="/baterias">
                      Luces y Sistemas
                    </Link>

                    <a href="/" className="dropdown-item">
                      Frenos
                    </a>
                    <a href="/" className="dropdown-item">
                      Clutches
                    </a>
                    <a href="/" className="dropdown-item">
                      Embragues
                    </a>
                    <a href="/" className="dropdown-item">
                      Bombas de Agua
                    </a>
                    <a href="/" className="dropdown-item">
                      Pernos y Casquillos
                    </a>
                    <a href="/" className="dropdown-item">
                      Mangueras
                    </a>
                    <a href="/" className="dropdown-item">
                      Patas de Cola
                    </a>
                    <a href="/" className="dropdown-item">
                      Quinta Rueda
                    </a>
                  </div>
                </div>
                <Link className="nav-item nav-link nav-texto" to="/solicitud">
                  Solicitud de credito
                </Link>

                <Link className="nav-item nav-link nav-texto" to="/">
                  Contacto
                </Link>

                <div className="nav-item pt-0 pb-0 mt-2 me-2 nav-link ">
                  <a href="https://www.instagram.com/dtainternacional/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA==">
                    <img src="/img/ig.png" alt="ig" width={"50px"} />
                  </a>
                </div>
                <div className="nav-item pt-0 pb-0 mt-2 nav-link me-2 ">
                  <a href="https://www.youtube.com/channel/UCdUkjlI-jcp2zehfpGID1QA">
                    <img src="/img/yt.png" alt="yt" width={"68px"} />
                  </a>
                  <small className="ms-2">Siguenos</small>
                </div>
                <div className="nav-link nav-item pt-0 pb-0 mt-1 nav-link me-2">
                  <a href="https://wa.me/18299614851">
                    <img src="/img/whats.png" alt="whats" width={"58px"} />
                  </a>
                </div>
                <div className=" nav-link nav-item pt-0 pb-0 mt-2 nav-link ">
                  <div className="col">
                    <div className="row">
                      <small
                        className="text-primary"
                        style={{
                          textTransform: "none",
                          fontSize: 0.95 + "vw",
                        }}
                      >
                        Necesita ayuda?
                      </small>
                    </div>
                    <div className="row">
                      <small
                        className="text-primary"
                        style={{ fontSize: 0.9 + "vw" }}
                      >
                        829-961-4851
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              {!isLoggedIn ? (
                <button
                  type="button"
                  className="btn btn-secondary me-3 "
                  onClick={handleShowModal}
                >
                  Registrarse
                  <i
                    className="bi bi-box-arrow-in-right ms-2"
                    style={{ fontSize: 1.3 + "rem" }}
                  ></i>
                </button>
              ) : (
                <>

                  {

                    <div className="perfil nav-item dropdown">
                      <a
                        href="/"
                        className="nav-link dropdown-toggle nav-texto"
                        data-bs-toggle="dropdown"
                        style={{ textTransform: "capitalize" }}
                      >
                        {JSON.parse(localStorage.getItem("LoginSession")).nombre}
                      </a>
                      <div className="dropdown-menu ">
                        <Link className="dropdown-item" to="/">
                          Perfil
                        </Link>
                        <Link className="dropdown-item" to="/">
                          Cotizaciones
                        </Link>
                        <Link className="dropdown-item" to="/">
                          Carrito
                        </Link>
                        <div className="dropdown-item" onClick={handleLogout}>
                          Salir
                        </div>


                      </div>
                    </div>
                  }
                  <div style={{ cursor: 'pointer' }}  >
                    <i className="bi bi-cart2"
                      style={{ fontSize: 2.3 + "rem" }}></i>
                    <span className="position-absolute top-1 start-99 translate-middle badge rounded-pill bg-danger">
                      5
                    </span>
                  </div>
                </>
              )}
            </div>
          </nav>
        </div>
        {/* Navbar End */}
      </header>
      <LoginModal
        setLoggedIn={setLoggedIn}
        show={showModal}
        handleClose={handleCloseModal}
      />
    </>
  );
}
