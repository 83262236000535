import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function Solicitud() {
  const [disable, setDisable] = useState(false);
  const [regmer, setRegmer] = useState(false);
  const [ced, setCed] = useState(false);
  const [refs, setRefs] = useState(false);
  const [soli, setSoli] = useState(false);
  const schema = yup.object().shape({
    nombreEmpresa: yup.string().required(),
    rnc: yup.string(),
    correo: yup.string().email().required(),
    telefono: yup.string(),
    direccion: yup.string(),
  });
  const {
    register,
    setValue,
    handleSubmit,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [error, setError] = useState(null);
  const isImage = (file) => {
    if (file) {
      const allowedMimeTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "application/pdf",
      ];
      return allowedMimeTypes.includes(file.type);
    } else {
      return true;
    }
  };
  const handleChange = (event) => {
    const file = event.target.files[0];

    if (file !== undefined && file !== null && file !== "") {
      const fileSizeLimit = 5 * 1024 * 1024; // 5 MB in bytes
      setError(null);

      if (!isImage(file)) {
        setValue(event.target.name, null);
        setError("Solo imagenes o PDFs");
      } else if (file.size > fileSizeLimit) {
        setValue(event.target.name, null);
        setError("No puede ser mayor a 10MB");
      } else {
        switch (event.target.name) {
          case "regmer":
            setRegmer(true);
            break;
          case "ced":
            setCed(true);
            break;
          case "refs":
            setRefs(true);
            break;
          case "soli":
            setSoli(true);
            break;

          default:
            break;
        }
      }
    }
  };

  const onSubmit = (data) => {
    setDisable(true);

    const MySwal = withReactContent(Swal);

    MySwal.fire({
      title: <p>Subiendo Documentos</p>,
      backdrop: false,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    }).then(() => {});

    axios
      .post("https://dtainternacional.com/apidta/enquiry.php", data, {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        MySwal.close();
        MySwal.fire({
          icon: "success",
          title: "Datos enviados correctamente",
          backdrop: false,
          allowOutsideClick: false,
        }).then(() => {
          setDisable(false);
        });
        // Handle the response from the server
        console.log(response.data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        setDisable(false);
        let mensaje = "";
        if (error.response === undefined) {
          mensaje = "Error en el servidor";
        } else {
          mensaje = error.response.data.mensaje;
        }
        MySwal.fire({
          icon: "error",
          title: "Oops...",
          text: mensaje,
        });
      });
  };
  return (
    <>
      {/* Page Header Start */}
      <div className="container-fluid page-header mb-5 py-5">
        <div className="container">
          <h1 className="display-3 text-white mb-3 animated slideInDown">
            Solicitud de credito
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb text-uppercase">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Inicio
                </Link>
              </li>
              <li
                className="breadcrumb-item text-white active"
                aria-current="page"
              >
                Solicitudes
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}

      {/* Solicitud Start */}
      <div className="container-xxl py-2">
        <div className="container">
          <div className="row g-4">
            <div className="col-md-12 wow fadeInUp" data-wow-delay="0.1s">
              <div className="bg-light p-5 h-100 d-flex align-items-center">
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  encType="multipart/form-data"
                >
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          id={"name"}
                          type={"text"}
                          className={
                            errors.nombreEmpresa
                              ? "form-control border-danger"
                              : "form-control"
                          }
                          placeholder=""
                          {...register("nombreEmpresa")}
                        />
                        <label htmlFor="name">
                          Nombre de Empresa o Persona
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          id={"rnc"}
                          type={"text"}
                          className={
                            errors.rnc
                              ? "form-control border-danger"
                              : "form-control"
                          }
                          {...register("rnc")}
                          placeholder={""}
                        />
                        <label htmlFor="rnc">RNC o C&eacute;dula</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          id={"correo"}
                          type={"email"}
                          className={
                            errors.correo
                              ? "form-control border-danger"
                              : "form-control"
                          }
                          placeholder=""
                          {...register("correo")}
                        />
                        <label htmlFor="correo">
                          Correo electr&oacute;nico
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          id={"telefono"}
                          type={"text"}
                          className={
                            errors.telefono
                              ? "form-control border-danger"
                              : "form-control"
                          }
                          {...register("telefono")}
                          placeholder={""}
                        />
                        <label htmlFor="telefono">Tel&eacute;fono</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <input
                          id={"direccion"}
                          type={"text"}
                          className={
                            errors.direccion
                              ? "form-control border-danger"
                              : "form-control"
                          }
                          {...register("direccion")}
                          placeholder={""}
                        />
                        <label htmlFor="direccion">Direcci&oacute;n</label>
                      </div>
                    </div>
                    <h5 className={"mt-4 mb-0"}>Subir Documentos</h5>
                    <div className={"col"}>
                      <div className={"col-md-7 mb-4"}>
                        <label htmlFor="regmer" className="form-label">
                          Registro Mercantil
                        </label>

                        <div className={"row"}>
                          <div className={"col-11"}>
                            <input
                              className={"form-control"}
                              type="file"
                              id="regmer"
                              style={{ backgroundColor: "white" }}
                              {...register("regmer", { required: true })}
                              onChange={handleChange}
                              onClick={() => {
                                setRegmer(false);
                              }}
                              required
                              accept="image/*, application/pdf"
                            />
                          </div>

                          <div className={"col-1 mt-1"}>
                            {regmer && (
                              <i
                                className="fa-lg bi bi-check-circle text-success"
                                style={{ fontSize: "30px" }}
                              ></i>
                            )}
                          </div>
                        </div>
                        {error && (
                          <small className={"text-danger"}>{error}</small>
                        )}
                      </div>
                      <div className={"col-md-7 mb-4"}>
                        <label htmlFor="ced" className="form-label">
                          C&eacute;dula
                        </label>

                        <div className={"row"}>
                          <div className={"col-11"}>
                            <input
                              className={"form-control"}
                              type="file"
                              id="ced"
                              style={{ backgroundColor: "white" }}
                              {...register("ced", { required: true })}
                              onChange={handleChange}
                              onClick={() => {
                                setCed(false);
                              }}
                              accept="image/*, application/pdf"
                            />
                          </div>

                          <div className={"col-1 mt-1"}>
                            {ced && (
                              <i
                                className="fa-lg bi bi-check-circle text-success"
                                style={{ fontSize: "30px" }}
                              ></i>
                            )}
                          </div>
                        </div>
                        {error && (
                          <small className={"text-danger"}>{error}</small>
                        )}
                      </div>

                      <div className={"col-md-7 mb-4"}>
                        <label htmlFor="refs" className="form-label">
                          Referencias comerciales
                        </label>

                        <div className={"row"}>
                          <div className={"col-11"}>
                            <input
                              className={"form-control"}
                              type="file"
                              id="refs"
                              style={{ backgroundColor: "white" }}
                              {...register("refs", { required: true })}
                              onChange={handleChange}
                              onClick={() => {
                                setRefs(false);
                              }}
                              accept="image/*, application/pdf"
                            />
                          </div>

                          <div className={"col-1 mt-1"}>
                            {refs && (
                              <i
                                className="fa-lg bi bi-check-circle text-success"
                                style={{ fontSize: "30px" }}
                              ></i>
                            )}
                          </div>
                        </div>
                        {error && (
                          <small className={"text-danger"}>{error}</small>
                        )}
                      </div>
                      <div className={"col-md-7 mb-4"}>
                        <label htmlFor="soli" className="form-label">
                          Carta solicitud p/ cr&eacute;dito
                        </label>

                        <div className={"row"}>
                          <div className={"col-11"}>
                            <input
                              className={"form-control"}
                              type="file"
                              id="soli"
                              style={{ backgroundColor: "white" }}
                              {...register("soli", { required: true })}
                              onChange={handleChange}
                              onClick={() => {
                                setSoli(false);
                              }}
                              accept="image/*, application/pdf"
                            />
                          </div>

                          <div className={"col-1 mt-1"}>
                            {soli && (
                              <i
                                className="fa-lg bi bi-check-circle text-success"
                                style={{ fontSize: "30px" }}
                              ></i>
                            )}
                          </div>
                        </div>
                        {error && (
                          <small className={"text-danger"}>{error}</small>
                        )}
                      </div>
                    </div>

                    <div className="col-12 pt-4">
                      <button
                        disabled={disable}
                        className="btn btn-primary w-100 py-3"
                        type="submit"
                      >
                        Enviar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Solicitud End */}
    </>
  );
}
