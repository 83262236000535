export default function Fact() {
  return (
    <>
      {/* Fact Start */}
      <div className="container-fluid fact bg-dark my-5 py-5">
        <div className="container">
          <div className="row g-4">
            <div
              className="col-md-8 col-lg-4 text-center wow fadeIn"
              data-wow-delay="0.1s"
            >
              <i className="fa fa-check fa-4x text-white mb-3" />

              <p className="text-white mb-0">27 A&ntilde;os de experiencia</p>
            </div>
            <div
              className="col-md-8 col-lg-4 text-center wow fadeIn"
              data-wow-delay="0.3s"
            >
              <i className="fa fa-users-cog fa-4x text-white mb-3" />

              <p className="text-white mb-0">Experimentado staff de ventas</p>
            </div>
            <div
              className="col-md-8 col-lg-4 text-center wow fadeIn"
              data-wow-delay="0.5s"
            >
              <i className="fa fa-users fa-4x text-white mb-3" />

              <p className="text-white mb-0">
                Mas de 3000 clientes sastifechos
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Fact End */}
    </>
  );
}
