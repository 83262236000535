import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

export const Carousel4 = () => (
  <Carousel
    autoPlay={true}
    showThumbs={false}
    infiniteLoop={true}
    centerMode={false}
    dynamicHeight={true}
    interval={4000}
  >
    <div className="owl-carousel-item position-relative">
      <img className="img-fluid" src={"img/lob.jpg"} alt="" />
      <div
        className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
        style={{ background: "rgba(0, 0, 0, .4)" }}
      >
        <div className="container">
          <div className="row justify-content-start">
            <div className="col-10 col-lg-8" style={{ textAlign: "left" }}>
              <h5 className="text-white text-uppercase mb-3 animated slideInDown">
                {" "}
              </h5>
              <h1
                className="display-3 text-white animated slideInDown mb-4"
                style={{ lineHeight: "1.1" }}
              >
                Tenemos más de 20 años de experiencia en el mercado de equipos
                pesados y de transporte
              </h1>

              <a
                href="/"
                className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
              >
                M&aacute; informaci&oacute;n
              </a>
              <a
                href="/"
                className="btn btn-secondary py-md-3 px-md-5 animated slideInRight"
              >
                Cotizar
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="owl-carousel-item position-relative">
      <img className="img-fluid" src={"img/truck-1.jpg"} alt="" />

      <div
        className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
        style={{ background: "rgba(0, 0, 0, .4)" }}
      >
        <div className="container">
          <div className="row justify-content-start">
            <div className="col-10 col-lg-8" style={{ textAlign: "left" }}>
              <h5 className="text-white text-uppercase mb-3 animated slideInDown">
                {" "}
              </h5>
              <h1
                className="display-3 text-white animated slideInDown mb-4"
                style={{ lineHeight: "1.1" }}
              >
                Nos dedicamos la venta de Repuestos y Servicios generales para
                vehículos&nbsp;de&nbsp;transporte.
              </h1>

              <a
                href="/"
                className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
              >
                M&aacute; informaci&oacute;n
              </a>
              <a
                href="/"
                className="btn btn-secondary py-md-3 px-md-5 animated slideInRight"
              >
                Cotizar
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="owl-carousel-item position-relative">
      <img className="img-fluid" src={"img/vendedor.jpg"} alt="" />
      <div
        className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
        style={{ background: "rgba(0, 0, 0, .4)" }}
      >
        <div className="container">
          <div className="row justify-content-start">
            <div className="col-10 col-lg-8" style={{ textAlign: "left" }}>
              <h5 className="text-white text-uppercase mb-3 animated slideInDown">
                {" "}
              </h5>
              <h1
                className="display-3 text-white animated slideInDown mb-4"
                style={{ lineHeight: "1.1" }}
              >
                Contamos con profesionales altamente capacitados, através de
                cursos, talleres y&nbsp;entrenamientos
              </h1>

              <a
                href="/"
                className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
              >
                M&aacute; informaci&oacute;n
              </a>
              <a
                href="/"
                className="btn btn-secondary py-md-3 px-md-5 animated slideInRight"
              >
                Cotizar
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Carousel>
);
