import React, { useContext } from "react";

import { AppContext } from "../App.js";

export default function Footer() {
  const { setShowNewsLetter } = useContext(AppContext);

  const handleOpenNews = () => {
    setShowNewsLetter(true);
  };

  return (
    <>
      {/* Footer Start */}
      <div
        className="container-fluid bg-secondary text-light footer pt-5 mt-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-3 col-md-6">
              <h4 className="text-light mb-4">Direcci&oacute;n</h4>
              <p className="mb-2">
                <i className="fa fa-map-marker-alt me-2" />
                Calle Seibo No. 5 casi Esq. San Martin, Villa Juana, Santo
                Domingo.
              </p>
              <p className="mb-2">
                <i className="fa fa-phone-alt me-3" />
                809-689-7403
              </p>
              <p className="mb-2">
                <i className="fa fa-envelope me-3" />
                info@example.com
              </p>
              <div className="d-flex pt-2">
                <a href="https://dtainternacional.com/apidta/qr/dtalinktree.html">
                  <img src="img/redesblanco.png" width="50%" alt="" />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <h4 className="text-light mb-4">Horario</h4>
              <h6 className="text-light">Lunes - Viernes:</h6>
              <p className="mb-4">08.00 AM - 06.00 PM</p>
              <h6 className="text-light">Sabado:</h6>
              <p className="mb-0">08.00 AM - 01.00 PM</p>
            </div>
            <div className="col-lg-3 col-md-6">
              <h4 className="text-light mb-4">Servicios</h4>
              <a className="btn btn-link" href="/">
                Instalaci&oacute;n de repuestos
              </a>
              <a className="btn btn-link" href="/">
                Servicio al cliente
              </a>
              <a className="btn btn-link" href="/">
                Inventario extenso
              </a>
              <a className="btn btn-link" href="/">
                Asesoramiento T&eacute;nico
              </a>
              <a className="btn btn-link" href="/">
                Garant&iacute;a
              </a>
            </div>
            <div className="col-lg-3 col-md-6">
              <h4 className="text-light mb-4">Newsletter</h4>
              <p>Suscribete para recibir las ultimas noticias</p>
              <div
                className="position-relative mx-auto"
                style={{ maxWidth: 400 }}
              >
                <button
                  onClick={handleOpenNews}
                  type="button"
                  className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2"
                >
                  Suscribete
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="copyright">
            <div className="row">
              <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                ©{" "}
                <a className="border-bottom" href="/">
                  DTA internacional
                </a>
                , Todos los derechos reservados.
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer End */}
    </>
  );
}
