export default function About() {
  return (
    <>
      {/* About Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h6 className="text-secondary text-uppercase">Sobre Nosotros</h6>
              <h1 className="mb-4" style={{ fontSize: "2.4rem" }}>
                Desde 1998 ofreciendo soluciones al mercado del transporte de
                camiones y equipos pesados
              </h1>
              <p className="mb-4">
                Nuestro equipo de ventas cuenta con profesionales altamente
                capacitados a trav&eacute;s de cursos, talleres y
                entrenamientos. Enfocados en las diferentes &aacute;reas de
                repuestos y accesorios en general, con el prop&oacute;sito de
                brindarles servicios de soluci&oacute;n.
              </p>
              <p className="fw-medium text-primary">
                <i className="fa fa-check text-success me-3" />
                El m&aacute;s completo inventario en respuestos
              </p>
              <p className="fw-medium text-primary">
                <i className="fa fa-check text-success me-3" />
                Entrega a todo el territorio nacional
              </p>
              <p className="fw-medium text-primary">
                <i className="fa fa-check text-success me-3" />
                Pedidos y consultas en linea
              </p>
              <div className="bg-primary d-flex align-items-center p-4 mt-5">
                <div
                  className="d-flex flex-shrink-0 align-items-center justify-content-center bg-white"
                  style={{ width: 60, height: 60 }}
                >
                  <i className="fa fa-phone-alt fa-2x text-primary" />
                </div>
                <div className="ms-3">
                  <p className="fs-5 fw-medium mb-2 text-white">
                    Asistencia 24/7
                  </p>
                  <h3 className="m-0 text-secondary">+829 961 4851</h3>
                </div>
              </div>
            </div>
            <div className="col-lg-6 pt-4" style={{ minHeight: 500 }}>
              <div
                className="position-relative h-100 wow fadeInUp"
                data-wow-delay="0.5s"
              >
                <img
                  className="position-absolute img-fluid w-100 h-100"
                  src="img/truckman1.jpg"
                  style={{ objectFit: "cover", padding: "0 0 50px 100px" }}
                  alt=""
                />
                <img
                  className="position-absolute start-0 bottom-0 img-fluid bg-white pt-2 pe-2 w-50 h-50"
                  src="img/vendedor.jpg"
                  style={{ objectFit: "cover" }}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}
    </>
  );
}
