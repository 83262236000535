import { Link } from "react-router-dom";
export default function Services() {
  const video = (
    <div className="container mt-4">
      <video controls width="100%">
        <source src="img/DTAVIDEO.mp4" type="video/mp4" />
        Sorry, your browser doesn't support videos.
      </video>
    </div>
  );
  return (
    <>
      {video}

      {/* Service Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-4">
            <div
              className="col-lg-4 col-md-6 service-item-top wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="overflow-hidden">
                <img
                  className="img-fluid w-100 h-100"
                  src="img/kit.jpg"
                  alt=""
                />
              </div>
              <div className="d-flex align-items-center justify-content-between bg-light p-4">
                <h5 className="text-truncate me-3 mb-0">
                  Kits de reparaci&oacute;n
                </h5>
                <a
                  className="btn btn-square btn-outline-primary border-2 border-white flex-shrink-0"
                  href="/"
                >
                  <i className="fa fa-arrow-right" />
                </a>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 service-item-top wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="overflow-hidden">
                <img
                  className="img-fluid w-100 h-100"
                  src="img/service-2.jpg"
                  alt=""
                />
              </div>
              <div className="d-flex align-items-center justify-content-between bg-light p-4">
                <h5 className="text-truncate me-3 mb-0">Tambores</h5>
                <a
                  className="btn btn-square btn-outline-primary border-2 border-white flex-shrink-0"
                  href="/"
                >
                  <i className="fa fa-arrow-right" />
                </a>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 service-item-top wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="overflow-hidden">
                <img
                  className="img-fluid w-100 h-100"
                  src="img/Manguera.jpg"
                  alt=""
                />
              </div>
              <div className="d-flex align-items-center justify-content-between bg-light p-4">
                <h5 className="text-truncate me-3 mb-0">Mangueras</h5>
                <a
                  className="btn btn-square btn-outline-primary border-2 border-white flex-shrink-0"
                  href="/"
                >
                  <i className="fa fa-arrow-right" />
                </a>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 service-item-top wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="overflow-hidden">
                <img
                  className="img-fluid w-100 h-100"
                  src="img/Frenos.jpg"
                  alt=""
                />
              </div>
              <div className="d-flex align-items-center justify-content-between bg-light p-4">
                <h5 className="text-truncate me-3 mb-0">Sistema de frenos</h5>
                <a
                  className="btn btn-square btn-outline-primary border-2 border-white flex-shrink-0"
                  href="/"
                >
                  <i className="fa fa-arrow-right" />
                </a>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 service-item-top wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="overflow-hidden">
                <img
                  className="img-fluid w-100 h-100"
                  src="img/service-1.jpg"
                  alt=""
                />
              </div>
              <div className="d-flex align-items-center justify-content-between bg-light p-4">
                <h5 className="text-truncate me-3 mb-0">Radiadores</h5>
                <a
                  className="btn btn-square btn-outline-primary border-2 border-white flex-shrink-0"
                  href="/"
                >
                  <i className="fa fa-arrow-right" />
                </a>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 service-item-top wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="overflow-hidden">
                <img
                  className="img-fluid w-100 h-100"
                  src="img/Luces.jpg"
                  alt=""
                />
              </div>
              <div className="d-flex align-items-center justify-content-between bg-light p-4">
                <h5 className="text-truncate me-3 mb-0">Luces y accesorios</h5>
                <a
                  className="btn btn-square btn-outline-primary border-2 border-white flex-shrink-0"
                  href="/"
                >
                  <i className="fa fa-arrow-right" />
                </a>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 service-item-top wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="overflow-hidden">
                <img
                  className="img-fluid w-100 h-100"
                  src="img/quintarueda.jpg"
                  alt=""
                />
              </div>
              <div className="d-flex align-items-center justify-content-between bg-light p-4">
                <h5 className="text-truncate me-3 mb-0">Quinta Rueda</h5>
                <a
                  className="btn btn-square btn-outline-primary border-2 border-white flex-shrink-0"
                  href="/"
                >
                  <i className="fa fa-arrow-right" />
                </a>
              </div>
            </div>{" "}
            <div
              className="col-lg-4 col-md-6 service-item-top wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="overflow-hidden">
                <img
                  className="img-fluid w-100 h-100"
                  src="img/exide.jpg"
                  alt=""
                />
              </div>
              <div className="d-flex align-items-center justify-content-between bg-light p-4">
                <h5 className="text-truncate me-3 mb-0">Bater&iacute;as</h5>
                <a
                  className="btn btn-square btn-outline-primary border-2 border-white flex-shrink-0"
                  href="/"
                >
                  <i className="fa fa-arrow-right" />
                </a>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 service-item-top wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="overflow-hidden">
                <img
                  className="img-fluid w-100 h-100"
                  src="img/lubriguard.jpg"
                  alt=""
                />
              </div>
              <div className="d-flex align-items-center justify-content-between bg-light p-4">
                <h5 className="text-truncate me-3 mb-0">Lubricantes</h5>

                <Link
                  className="btn btn-square btn-outline-primary border-2 border-white flex-shrink-0"
                  to="/lubricantes#"
                >
                  <i className="fa fa-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Service End */}
    </>
  );
}
